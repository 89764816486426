/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module BibleStudyIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents a Bible Study icon element.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#000000').
 *
 * @returns {React.ReactElement} The Bible Study Icon element.
 */
export default function BibleStudyIcon({ color = '#000000' }) {
  return (
    <div className="icon" data-color={color} data-testid="bible-study-icon">
      <svg
        width="17"
        height="18"
        viewBox="0 0 17 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.3969 14.3969V14.4969H13.4969C13.9395 14.4969 14.2969 14.8543 14.2969 15.2969C14.2969 15.7395 13.9395 16.0969 13.4969 16.0969H4.49688C3.06148 16.0969 1.89688 14.9323 1.89688 13.4969V4.49687C1.89688 3.06148 3.06148 1.89688 4.49688 1.89688H13.4969C13.9395 1.89688 14.2969 2.25429 14.2969 2.69687V11.6969C14.2969 12.1395 13.9395 12.4969 13.4969 12.4969H13.3969V12.5969V14.3969ZM11.7969 12.5969V12.4969H11.6969H4.49688C3.94383 12.4969 3.49688 12.9438 3.49688 13.4969C3.49688 14.0499 3.94383 14.4969 4.49688 14.4969H11.6969H11.7969V14.3969V12.5969ZM8.09688 3.49687C7.79415 3.49687 7.54688 3.74415 7.54688 4.04688V5.29688H6.29688C5.99415 5.29688 5.74688 5.54415 5.74688 5.84687V6.74687C5.74688 7.0496 5.99415 7.29687 6.29688 7.29687H7.54688V10.3469C7.54688 10.6496 7.79415 10.8969 8.09688 10.8969H8.99688C9.2996 10.8969 9.54688 10.6496 9.54688 10.3469V7.29687H10.7969C11.0996 7.29687 11.3469 7.0496 11.3469 6.74687V5.84687C11.3469 5.54415 11.0996 5.29688 10.7969 5.29688H9.54688V4.04688C9.54688 3.74415 9.2996 3.49687 8.99688 3.49687H8.09688Z"
          fill={color}
          stroke={color}
          strokeWidth="0.2"
        />
      </svg>
    </div>
  );
}
