/**
 * @module GroupMap
 */
/* global mapboxgl */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useAlgoliaSearch from '../../hooks/useAlgoliaSearch';
import useLCProfile from '../../hooks/useLCProfile';
import GlobeIcon from '../ui/GlobeIcon';
import LocationIcon from '../ui/LocationIcon';
import {
  generateLocationLabel,
  generateLocationTypeLabel,
} from '../../utils/helpers';
import { MAPBOX_CONFIG } from '../../utils/constants';
import { STRINGS } from '../../utils/strings';
import { ANALYTICS, triggerSegmentTrack } from '../../utils/analytics';
import { logError } from '../../utils/errorLogger';
import './DetailPage.scss';

/**
 * Convenience constant for generic Mapbox events to track in Segment analytics.
 */
/* istanbul ignore next */
const GENERIC_MAP_EVENTS_FOR_ANALYTICS = {
  dragstart: {
    action: ANALYTICS.actions.dragStart,
  },
  mouseover: {
    action: ANALYTICS.actions.mouseEnter,
  },
  zoomstart: {
    action: ANALYTICS.actions.zoomStart,
  },
};

/**
 * Convenience component with conditional logic to decide on and return in-person group markup.
 *
 * Note: The ignore is added because technically, the conditionals elsewhere in
 * the component that end up calling this method should prevent the fallback of
 * markup of the default return, but it is in place as an absolute failsafe in
 * case there is no meetingLocation city/state/country to display.
 *
 * @param {object} props - The component props object.
 * @param {object} props.groupData - The LifeGroupData data object.
 * @param {boolean} props.hasLocationInfo - Boolean flag denoting whether or not there is location information.
 *
 * @returns {React.ReactElement} The InPersonMarkup component.
 */
/* istanbul ignore next */
function InPersonMarkup({ groupData, hasLocationInfo }) {
  if (hasLocationInfo) {
    return (
      <>
        <LocationIcon />{' '}
        {generateLocationLabel({
          locationData: groupData.meetingLocation,
        })}
      </>
    );
  } else if (groupData?.meetingLocation?.locationType) {
    return (
      <>
        <LocationIcon />{' '}
        {generateLocationTypeLabel({
          locationData: groupData.meetingLocation,
        })}
      </>
    );
  }
  return (
    <>
      <LocationIcon /> {STRINGS.groupDetail.map.meetsInPerson}
    </>
  );
}

/**
 * Convenience component with conditional logic to decide on and return group description markup.
 *
 * @param {object} props - The component props object.
 * @param {object} props.groupData - The LifeGroupData data object.
 * @param {boolean} props.hasLocationInfo - Boolean flag denoting whether or not there is location information.
 * @param {boolean} props.meetsOnline - Boolean flag denoting whether or not the group meets online.
 *
 * @returns {React.ReactElement} The Description component.
 */
function DescriptionMarkup({ groupData, hasLocationInfo, meetsOnline }) {
  if (!meetsOnline && hasLocationInfo) {
    return (
      <p>
        {generateLocationTypeLabel({
          locationData: groupData.meetingLocation,
        })}
      </p>
    );
  }
  return <p>The LifeGroup Leader will share more meeting details.</p>;
}

/**
 * Represents a section of a LifeGroup detail page including the meeting location and map (if in-person) the group.
 *
 * @param {object} props - The component props object.
 * @param {object} props.groupData - The LifeGroupData data object, including location data and a _geoloc object attribute with lat and lng coordinates.
 *
 * @returns {React.ReactElement} - The GroupMap component.
 */
function GroupMap({ groupData }) {
  const { user } = useAuth();
  const { userProfileData } = useLCProfile();
  const { getDataForAnalytics } = useAlgoliaSearch();
  const mapRef = React.useRef();
  const mapContainerRef = React.useRef();
  const [isMapConfigured, setIsMapConfigured] = React.useState(false);
  const [hasLocationInfo, setHasLocationInfo] = React.useState(
    groupData?.meetingLocation?.city ||
      groupData?.meetingLocation?.state ||
      groupData?.meetingLocation?.country,
  );
  const [hasGeolocationInfo, setHasGeolocationInfo] = React.useState(
    groupData?._geoloc?.lat && groupData?._geoloc?.lng,
  );
  const [meetsOnline, setMeetsOnline] = React.useState(
    Array.isArray(groupData?.facets?.meetingType)
      ? groupData?.facets?.meetingType?.[0]?.toLowerCase() === 'online'
      : groupData?.facets?.meetingType?.toLowerCase() === 'online',
  );
  let mapTimer;

  /**
   * Convenience function to trigger the analytics event, using the provided event and action with all other data shared for all events tracked in the component.
   *
   * Note: The ignore directive is added since we are not covering third-party integrations/interactions with our own tests.
   *
   * @param {object} params - The function params object.
   * @param {string} [params.action] - The action value associated with the event, which is used as the `properties.action` value.
   * @param {string} [params.event] - The event value associated with the event, which is used as the `event` value.
   */
  /* istanbul ignore next */
  function trackMapAnalytics({
    action = ANALYTICS.actions.clicked,
    event = ANALYTICS.events.mapEvent,
  }) {
    const dataForAnalytics = getDataForAnalytics();
    triggerSegmentTrack({
      dataForAnalytics,
      event,
      properties: {
        action,
        component: ANALYTICS.components.lifeGroupDetailPage,
        component_url: null,
      },
      user,
      userProfileData,
    });
  }

  /**
   * Convenience function to create a geo JSON circle for the map.
   *
   * Source: https://stackoverflow.com/a/39006388/1914233.
   *
   * Note: With Mapbox being third party and not tested in other projects, this
   * convenience function is being ignored as with the map.on('load') which
   * triggers this to run and generate the geo circle.
   *
   * @param {Array<number>} center - Array of longitude and latitude coordinates for the map center.
   * @param {*} radiusInKm - The radius of the circle in kilometers.
   * @param {*} points - Optional number of points to use in drawing the circle.
   *
   * @returns {object} A geojson data object.
   */
  /* istanbul ignore next */
  const createGeoJSONCircle = function (center, radiusInKm, points) {
    if (!points) {
      points = 64;
    }

    const coords = {
      latitude: center[1],
      longitude: center[0],
    };

    const km = radiusInKm;

    const ret = [];
    const distanceX =
      km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
    const distanceY = km / 110.574;

    let theta;
    let x;
    let y;
    for (let i = 0; i < points; i += 1) {
      theta = (i / points) * (2 * Math.PI);
      x = distanceX * Math.cos(theta);
      y = distanceY * Math.sin(theta);

      ret.push([coords.longitude + x, coords.latitude + y]);
    }
    ret.push(ret[0]);

    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [ret],
            },
          },
        ],
      },
    };
  };

  /**
   * Handler function for map resize event. Note that this includes logic for a
   * setTimeout, as there proved to be multiple resize events triggered for each
   * resize as the fullscreen was entered and the map resized. Adding the small
   * timeout allows only one of them to be triggered.
   */
  /* istanbul ignore next */
  function handleMapResize() {
    if (!mapTimer) {
      trackMapAnalytics({
        action: ANALYTICS.actions.resize,
        event: ANALYTICS.events.mapEvent,
      });
      mapTimer = setTimeout(() => {
        mapTimer = null;
      }, 250);
    }
  }

  /**
   * Convenience effect to set flags for location and online status.
   */
  React.useEffect(() => {
    setHasLocationInfo(
      groupData?.meetingLocation?.city ||
        groupData?.meetingLocation?.state ||
        groupData?.meetingLocation?.country,
    );
    setHasGeolocationInfo(groupData?._geoloc?.lat && groupData?._geoloc?.lng);
    setMeetsOnline(
      Array.isArray(groupData?.facets?.meetingType)
        ? groupData?.facets?.meetingType?.[0]?.toLowerCase() === 'online'
        : groupData?.facets?.meetingType?.toLowerCase() === 'online',
    );
  }, [groupData]);

  /**
   * Convenience effect to set up and instantiate the map.
   *
   * Note: Basic controls added to Mapbox mock; conditional check, load, and its
   * callback logic are not deemed necessary to test.
   */
  React.useEffect(() => {
    if (
      !isMapConfigured &&
      groupData?._geoloc?.lat &&
      groupData?._geoloc?.lng &&
      hasLocationInfo &&
      !meetsOnline &&
      mapContainerRef?.current &&
      !window.mapLoader
    ) {
      window.mapLoader = () => {
        /* istanbul ignore next */
        if (mapboxgl) {
          const center = [groupData._geoloc.lng, groupData._geoloc.lat]; // [Longitude, Latitude]
          mapboxgl.accessToken = MAPBOX_CONFIG.accessToken;
          const map = new mapboxgl.Map({
            center,
            container: mapContainerRef.current,
            style: `mapbox://styles/digeratilicenses/${MAPBOX_CONFIG.styleToken}`, // Style URL.
            zoom: 12,
          });
          map.addControl(new mapboxgl.FullscreenControl());
          map.addControl(new mapboxgl.NavigationControl());
          /* istanbul ignore next */
          map.on('load', () => {
            trackMapAnalytics({
              action: ANALYTICS.actions.loaded,
            });
            map.addSource('location', createGeoJSONCircle(center, 1.5));
            map.addLayer({
              id: 'location-approximation',
              layout: {},
              paint: {
                'fill-color': 'rgb(0, 200, 255)',
                'fill-opacity': 0.15,
              },
              source: 'location',
              type: 'fill',
            });
          });
          /* istanbul ignore next */
          map.on('error', () => {
            trackMapAnalytics({
              action: ANALYTICS.actions.error,
            });
            logError(error, { bugsnag: false });
          });
          /* istanbul ignore next */
          map.on('resize', handleMapResize);
          /* istanbul ignore next */
          Object.entries(GENERIC_MAP_EVENTS_FOR_ANALYTICS).forEach(
            ([eventKey, eventData]) => {
              map.on(`${eventKey}`, () => {
                trackMapAnalytics({
                  action: eventData.action,
                });
              });
            },
          );
          mapboxgl.current = map;

          return () => {
            setIsMapConfigured(true);
            mapRef?.current?.remove();
          };
        }
      };
    }
  }, [groupData, hasLocationInfo, isMapConfigured]);

  return (
    <>
      {groupData?.facets?.meetingType ? (
        <div
          className="lg-attribute-wrap bg-gray5 lg-map rounded"
          data-testid="lg-group-detail-map"
        >
          <div className="lg-attribute-text text-center">
            <>
              <h3>
                {meetsOnline ? (
                  <>
                    <GlobeIcon />
                    {` ${STRINGS.groupDetail.map.meetsOnline}`}
                  </>
                ) : (
                  <InPersonMarkup
                    groupData={groupData}
                    hasLocationInfo={hasLocationInfo}
                  />
                )}
              </h3>
              <DescriptionMarkup
                groupData={groupData}
                hasLocationInfo={hasLocationInfo}
                meetsOnline={meetsOnline}
              />
            </>
            {!meetsOnline && hasLocationInfo && hasGeolocationInfo ? (
              <>
                <div className="lg-map-container" ref={mapContainerRef} />
                <p className="lg-disclaimer">
                  {STRINGS.groupDetail.map.locationDisclaimer}
                </p>
              </>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default GroupMap;
