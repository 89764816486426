/**
 * @module DetailPage
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import { callSegmentPage } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { Log } from '@lifechurch/web-tools-io/dist/utils/helpers/browserLogger';
import { getRandomArrayItems } from '@lifechurch/web-tools-io/dist/utils/helpers/arrayHelper';
import { camelToSnake } from '@lifechurch/web-tools-io/dist/utils/helpers/strings';
import { transformKeys } from '@lifechurch/web-tools-io/dist/utils/helpers/transformKeys';
import qs from 'qs';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import useAlgoliaSearch from '../../hooks/useAlgoliaSearch';
import useLCProfile from '../../hooks/useLCProfile';
import useMagnolia from '../../hooks/useMagnolia';
import { ANALYTICS, triggerSegmentTrack } from '../../utils/analytics';
import {
  ALGOLIA_CONFIG,
  APP_NAME,
  ENVIRONMENT,
  OVERRIDE_ALGOLIA_QUERY_KEY,
  SNAKE_CASE_EXCEPTIONS,
} from '../../utils/constants';
import { logError } from '../../utils/errorLogger';
import { getExperimentalCookieValue } from '../../utils/helpers';
import { STRINGS } from '../../utils/strings';
import AboutGroup from './AboutGroup';
import AgeAndFrequency from './AgeAndFrequency';
import ChildrenAndCampusLocation from './ChildrenAndCampusLocation';
import ConnectionHelp from './ConnectionHelp';
import ContactForm from './ContactForm';
import GroupDetailHeader from './GroupDetailHeader';
import GroupMap from './GroupMap';
import InterestContact from './InterestContact';
import MoreLikeThis from './MoreLikeThis';
import StickyBar from './StickyBar';
import './DetailPage.scss';

/**
 * Represents the main UI view of the LifeGroup Detail page with elements specifying group details and information.
 *
 * @param {object} props - The component props object.
 * @param {string} props.groupId - The unique group objectId value.
 *
 * @returns {React.ReactElement} The DetailPage component.
 */
function DetailPage({ groupId, ...passThroughProps }) {
  const { user } = useAuth();
  const { userProfileData } = useLCProfile();
  const { isMobile } = useWindowSize();
  const { fetchRecommendations, getDataForAnalytics, getObject, init } =
    useAlgoliaSearch();
  const { footerData, menuData } = useMagnolia();

  const isInMoreLikeThis =
    process.env.ENABLE_MORE_LIKE_THIS &&
    process.env.ENABLE_MORE_LIKE_THIS.toString() === 'true';
  const [moreLikeThis, setMoreLikeThis] = React.useState(
    passThroughProps?.moreLikeThis,
  );
  const [lgAnalyticsData, setLgAnalyticsData] = React.useState({});

  /**
   * Group data object.
   */
  const [groupData, setGroupData] = React.useState();

  /**
   * Contact form visibility state tracker.
   */
  const [isContactFormVisible, setIsContactFormVisible] = React.useState(false);

  /**
   * Handler function for Interest and Contact LG pastor button click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleInterestMessageClick(event) {
    const firstName = userProfileData?.first_name;
    const lastName = userProfileData?.last_name;
    const email = userProfileData?.email;
    const phoneNumber = userProfileData?.phone_numbers?.[0]?.number;
    const location = groupData?.campus?.shortCode?.toLowerCase();
    let query = '';
    const queryParams = [];
    if (firstName) {
      queryParams.push(`FirstName=${firstName}`);
    }
    if (lastName) {
      queryParams.push(`LastName=${lastName}`);
    }
    if (lastName) {
      queryParams.push(`Email=${email}`);
    }
    if (lastName) {
      queryParams.push(`Phone=${phoneNumber}`);
    }
    if (location) {
      queryParams.push(`Location=${location}`);
    }
    query = queryParams.join('&');

    const dataForAnalytics = getDataForAnalytics();
    triggerSegmentTrack({
      dataForAnalytics,
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.components.lifeGroupDetailPage,
        component_url: null,
        label: event.currentTarget.textContent,
        lifegroup: lgAnalyticsData,
      },
    });

    const questionUrl = `https://my.life.church/forms/lifegroup-question${
      query ? '?' : ''
    }${query}`;
    window.open(questionUrl, '_blank');
  }

  /**
   * Handler function for Back to Search button click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleBackToSearchClick(event) {
    const dataForAnalytics = getDataForAnalytics();
    triggerSegmentTrack({
      dataForAnalytics,
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.components.lifeGroupDetailPage,
        component_url: null,
        label: event.currentTarget.textContent,
        lifegroup: lgAnalyticsData,
      },
      user,
      userProfileData,
    });
  }

  /**
   * Handler function for Interest and Contact component Contact button click event.
   *
   * Note: Ignoring handler function as contact form test handled elsewhere, and
   * to save the complexity of mocking and state management in test file.
   *
   * @param {Event} event - The Event object associated with the click.
   * @param {object} overrideData - Optional data object with override properties for analytics tracking.
   */
  /* istanbul ignore next */
  function handleInterestButtonClick(event, overrideData) {
    setIsContactFormVisible(true);
    const dataForAnalytics = getDataForAnalytics();
    triggerSegmentTrack({
      dataForAnalytics,
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component:
          overrideData?.component || ANALYTICS.components.lifeGroupDetailPage,
        component_url: null,
        label: event.currentTarget.textContent,
        lifegroup: lgAnalyticsData,
      },
      user,
      userProfileData,
    });
  }

  /**
   * Handler function for contact form close click event.
   *
   * Note: Ignoring handler function as contact form test handled elsewhere, and
   * to save the complexity of mocking and state management in test file.
   *
   * @param {Event} event - The Event object associated with the close click.
   */
  /* istanbul ignore next */
  function handleContactFormCloseClick(event) {
    event.preventDefault();
    setIsContactFormVisible(false);
  }

  /**
   * Convenience function to dynamically update page meta tags with group-specific data.
   *
   * Note: This function is intentionally verbose in how each tag is targeted
   * as an individual constant and conditionally checked for updating its value,
   * in an effort to ensure accuracy for each one. Also, with it being lots of
   * extra conditional logic for non-UI elements, adding ignore directive.
   */
  /* istanbul ignore next */
  function updatePageMetaData() {
    const canonicalUrlTag = document.querySelector('link[rel="canonical"]');
    const metaOgUrlTag = document.querySelector('meta[property="og:url"]');
    const metaOgDescriptionTag = document.querySelector(
      'meta[property="og:description"]',
    );
    const metaOgTitleTag = document.querySelector('meta[property="og:title"]');
    const metaOgImageTag = document.querySelector('meta[property="og:image"]');
    const metaOgImageHeightTag = document.querySelector(
      'meta[property="og:image:height"]',
    );
    const metaOgImageWidthTag = document.querySelector(
      'meta[property="og:image:width"]',
    );
    const metaTwitterImageTag = document.querySelector(
      'meta[name="twitter:image"]',
    );
    const metaDescriptionTag = document.querySelector(
      'meta[name="description"]',
    );
    if (canonicalUrlTag) {
      canonicalUrlTag.setAttribute('href', window.location.href);
    }
    if (metaOgUrlTag) {
      metaOgUrlTag.setAttribute('content', window.location.href);
    }
    if (metaDescriptionTag) {
      metaDescriptionTag.setAttribute('content', groupData.description);
    }
    if (metaOgDescriptionTag) {
      metaOgDescriptionTag.setAttribute('content', groupData.description);
    }
    if (metaOgTitleTag) {
      metaOgTitleTag.setAttribute(
        'content',
        `${groupData.name} | ${STRINGS.meta.title}`,
      );
    }
    if (metaOgImageTag && groupData.publicCoverImageUrl) {
      metaOgImageTag.setAttribute(
        'content',
        `${groupData.publicCoverImageUrl}`,
      );
      // Remove height and width tags since image dimensions not known.
      if (metaOgImageHeightTag) {
        metaOgImageHeightTag.remove();
      }
      if (metaOgImageWidthTag) {
        metaOgImageWidthTag.remove();
      }
    }
    if (metaTwitterImageTag && groupData.publicCoverImageUrl) {
      metaTwitterImageTag.setAttribute(
        'content',
        `${groupData.publicCoverImageUrl}`,
      );
    }
    document.title = `${groupData.name} | ${STRINGS.meta.title}`;
  }

  /**
   * Convenience function to dynamically inject third party library scripts and
   * stylesheets into the document.head.
   *
   * Note: Ignore added as it is not necessary to test loading of actual files.
   */
  /* istanbul ignore next */
  function injectThirdPartyLibraries() {
    // Mapbox script and styles.
    const mapboxScriptTag = document.createElement('script');
    const mapboxStylesheetLinkTag = document.createElement('link');
    mapboxScriptTag.type = 'text/javascript';
    mapboxScriptTag.src =
      'https://api.mapbox.com/mapbox-gl-js/v2.2.0/mapbox-gl.js';
    mapboxScriptTag.onload = () => {
      window.onMapboxLoaded();
    };
    mapboxStylesheetLinkTag.type = 'text/css';
    mapboxStylesheetLinkTag.rel = 'stylesheet';
    mapboxStylesheetLinkTag.href =
      'https://api.mapbox.com/mapbox-gl-js/v2.2.0/mapbox-gl.css';

    // Phone number (react-phone-number-input and libphonenumber-js).
    const libPhoneNumberJsScriptTag = document.createElement('script');
    const reactPhoneNumberInputStylesheetLinkTag =
      document.createElement('link');
    libPhoneNumberJsScriptTag.type = 'text/javascript';
    libPhoneNumberJsScriptTag.src =
      'https://unpkg.com/libphonenumber-js@^1.12.5/bundle/libphonenumber-min.js';
    reactPhoneNumberInputStylesheetLinkTag.type = 'text/css';
    reactPhoneNumberInputStylesheetLinkTag.rel = 'stylesheet';
    reactPhoneNumberInputStylesheetLinkTag.href =
      'https://unpkg.com/react-phone-number-input@3.x/bundle/style.css';

    // Add dynamically to the head tag.
    document.head.appendChild(mapboxScriptTag);
    document.head.appendChild(mapboxStylesheetLinkTag);
    document.head.appendChild(libPhoneNumberJsScriptTag);
    document.head.appendChild(reactPhoneNumberInputStylesheetLinkTag);
  }

  /**
   * Convenience effect to fetch group data once a groupId is set.
   *
   * Additionally, it fetches the user from local storage to pass along to
   * Segment for analytics and the page call.
   */
  React.useEffect(() => {
    async function fetchData() {
      try {
        await getObject({
          callback: ({ searchResults }) => {
            if (searchResults) {
              setGroupData(searchResults);
              const lgAnalyticsDataResult = transformKeys(
                searchResults,
                camelToSnake,
                SNAKE_CASE_EXCEPTIONS,
              );
              setLgAnalyticsData(lgAnalyticsDataResult);

              let storedUser;
              try {
                storedUser =
                  /* istanbul ignore next */ window?.localStorage?.getItem(
                    'user_profile',
                  )
                    ? JSON.parse(window.localStorage.getItem('user_profile'))
                    : null;

                // Trigger Segment page call.
                callSegmentPage({
                  category: '',
                  name: APP_NAME,
                  properties: {
                    experimental_flag: getExperimentalCookieValue(),
                    lifegroup: lgAnalyticsDataResult,
                    logged_in: !!storedUser,
                    path: window?.location?.pathname,
                    preferred_campus:
                      userProfileData?.preferred_campus_code || null,
                    referrer: document?.referrer || null,
                    title: `${APP_NAME} App`,
                    url: window.location.href,
                    user_id:
                      storedUser?.[
                        'https://www.life.church/rock_person_alias_id'
                      ],
                  },
                });
              } catch (error) {
                /* istanbul ignore next */
                logError(error, { bugsnag: false });
              }
            } else {
              alert(STRINGS.groupDetail.notFoundAlert);
              window.location.href =
                window.location.href.split('/lifegroup/')[0];
            }
          },
          objectID: groupId,
        });
      } catch (error) {
        /* istanbul ignore next */
        Log.error(error);
      }
    }
    /* istanbul ignore next */
    if (groupId && !groupData) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  /**
   * Convenience effect to fetch recommendations based on the group id and data.
   *
   * Note: Ignoring the conditional within, even though the logic still happens
   * and is asserted for the most part in the tests. This is because the More
   * Like This functionality is an experiment, and testing for exact facet
   * filters and mocking through the variations of the facets is not needed when
   * mock data can be used to return the recommendations.
   */
  React.useEffect(() => {
    /* istanbul ignore next */
    if (isInMoreLikeThis && !moreLikeThis && groupData?.facets) {
      const { children, campuses, genders, meetingType, seasonOfLife, topic } =
        groupData.facets;
      const topicNames = topic?.map((t) => t.name);
      const params = {
        'facets.genders': Array.isArray(genders)
          ? getRandomArrayItems(genders, 1)
          : genders,
        'facets.meetingType': meetingType,
        'facets.seasonOfLife': Array.isArray(seasonOfLife)
          ? getRandomArrayItems(seasonOfLife, 1)
          : seasonOfLife,
        'facets.topic.name': getRandomArrayItems(topicNames, 1),
      };
      const facetFilters = [];
      Object.entries(params).forEach(([key, value]) => {
        const individualFacetFilters = [];
        if (value && Array.isArray(value)) {
          value.forEach((v) => {
            individualFacetFilters.push(`${key}:${v}`);
          });
        } else if (value) {
          individualFacetFilters.push(`${key}:${value}`);
        }
        if (individualFacetFilters.length) {
          facetFilters.push(individualFacetFilters);
        }
      });
      fetchRecommendations({
        callback: /* istanbul ignore next */ ({ recommendedResults }) => {
          if (recommendedResults) {
            const finalResults = getRandomArrayItems(
              recommendedResults.hits,
              ALGOLIA_CONFIG.searchApiOptions.recommendationsLength,
            );
            setMoreLikeThis(finalResults);
          }
        },
        facetFilters,
        objectID: groupId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData, isInMoreLikeThis]);

  /**
   * Convenience effect to trigger updatePageMetaData() function when groupData
   * is set and populated.
   */
  React.useEffect(() => {
    if (groupData) {
      updatePageMetaData();
    }
  }, [groupData]);

  /**
   * Single-run convenience effect to add class name to body tag for specific
   * App class attribution and styles (by virtue of DetailPage.scss), and to
   * initialize the AlgoliaSearchContext.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    document?.body?.classList.add('detail-page');
    injectThirdPartyLibraries();

    const queryParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    const overrideAlgolia =
      ((queryParams &&
        ['1', 'true'].includes(queryParams[OVERRIDE_ALGOLIA_QUERY_KEY])) ||
        window.lcLtOverride) &&
      !ENVIRONMENT.production;
    window.lcLtOverride = overrideAlgolia;

    init({ isOverrideAlgolia: overrideAlgolia, isSearchPage: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        {menuData ? <Header menuData={menuData} /> : null}
        <div className="lg-detail-page" data-testid="lg-detail-page">
          {groupData ? (
            <>
              <GroupDetailHeader groupData={groupData} />
              <div className="lg-detail-age-time-map container">
                <div className="lg-attribute-group">
                  <AgeAndFrequency groupData={groupData} />
                  {!isMobile ? (
                    <ChildrenAndCampusLocation groupData={groupData} />
                  ) : null}
                </div>
                <div className="lg-attribute-group full-width justify-center lg-about-map-wrap">
                  {groupData.description ? (
                    <AboutGroup groupData={groupData} />
                  ) : null}
                  <GroupMap groupData={groupData} />
                </div>
                {isMobile ? (
                  <div className="lg-attribute-group">
                    <ChildrenAndCampusLocation groupData={groupData} />
                  </div>
                ) : null}
              </div>
              <hr className="divider container" />
              <div className="lg-attribute-group lg-detail-interest container">
                <InterestContact
                  groupLeaders={groupData.groupLeaders}
                  groupName={groupData.name}
                  onClick={handleInterestButtonClick}
                />
              </div>
              {isInMoreLikeThis && moreLikeThis?.length ? (
                <div className="lg-attribute-group">
                  <MoreLikeThis relatedGroups={moreLikeThis} />
                </div>
              ) : (
                <hr
                  className={`divider container clear${
                    !isMobile ? ' large' : ''
                  }`}
                />
              )}
              <div
                className={`lg-attribute-group ${
                  !isInMoreLikeThis ? 'bg-gray5' : ''
                }`}
              >
                <ConnectionHelp
                  groupData={groupData}
                  onBackToSearchClick={handleBackToSearchClick}
                  onInterestMessageClick={handleInterestMessageClick}
                />
              </div>
            </>
          ) : null}
          {
            /* istanbul ignore next */ isContactFormVisible ? (
              <ContactForm
                groupData={groupData}
                isOpen={isContactFormVisible}
                onCloseClick={handleContactFormCloseClick}
              />
            ) : null
          }
        </div>
        {footerData ? <Footer footerData={footerData} /> : null}
      </div>
      {groupData ? (
        <StickyBar
          groupLeaders={groupData.groupLeaders}
          groupName={groupData.name}
          onClick={handleInterestButtonClick}
        />
      ) : null}
    </>
  );
}

export default DetailPage;
