/**
 * @module apiClient
 */

/* eslint-disable max-classes-per-file */
import 'cross-fetch/polyfill';
import { paramsToQuery } from '@lifechurch/web-tools-io/dist/utils/helpers';
import { logError } from '../../../utils/errorLogger';

/**
 * Base apiClient that wraps fetch.
 *
 * @param {object} config - The config object.
 * @param {object} config.options - The config options.
 * @param {string} config.url - The url to fetch.
 *
 * @throws {Error}
 *
 * @returns {Promise} - The response model object.
 */
async function apiClient({ options, url }) {
  let errorMessage;
  try {
    const response = await fetch(url, options);
    let json;
    try {
      // Handle blank/null response (e.g. from preflight check) by getting
      // response text, checking length, then parsing as needed.
      const text = await response.text();
      if (text.length) {
        json = JSON.parse(text);
      } else {
        json = {};
      }
      json.status = response.status;
      /**
       * This is an absolute edge case preventative measure, as previously, the
       * Rock API had returned with blank body for all statuses, even errors.
       * As such, we needed to make up for it here and generate our own generic
       * error to pass back with the status, as the JSON.parse(text) just
       * produces a blank object that didn't have any sort of status or errors.
       * This is safe to ignore for tests, as it is severe guard of edge case.
       */
      /* istanbul ignore next */
      if (response.status >= 400) {
        json = json
          ? { ...json, status: response.status }
          : {
              errors: [
                {
                  form_field: 'general',
                  message: `Sorry, there was an error with the request to ${url}.`,
                },
              ],
              status: response.status,
            };
      }
    } catch (e) {
      /* istanbul ignore next */
      errorMessage = `Sorry, there was an error with the request to ${url}.`;

      /* istanbul ignore next */
      logError(new Error(errorMessage), { bugsnag: false });
      /* istanbul ignore next */
      json = {
        errors: [
          {
            form_field: 'general',
            message: errorMessage,
          },
        ],
        status: response.status,
      };
    }
    return json;
  } catch (error) {
    // Note: Only error logging browser console, as there are API calls made
    // that likely will fail/return non-200 status codes, in the case that a
    // user is not authenticated, as there's some calls that are made with
    // access token that may not be present, and thus, fallback data is used.
    /* istanbul ignore next */
    logError(new Error(errorMessage || error.message), {
      browserConsole: true,
      bugsnag: false,
      windowAlert: false,
    });
    /* istanbul ignore next */
    return null;
  }
}

/**
 * Make a GET call.
 *
 * @param {object} config - The configuration object.
 * @param {object} [config.options] - Passed through as fetch options.
 * @param {object} [config.params] - GET params, will be converted to valid query string with support for bracket-less arrays.
 * @param {string} config.url - The URL endpoint path.
 *
 * @returns {Promise} The fetch response.
 */
export async function get({
  options = {},
  params = {},
  url,
  ...passThroughProps
}) {
  const queryParams = paramsToQuery(params).length
    ? `?${paramsToQuery(params)}`
    : '';
  return apiClient({
    options: {
      ...options,
      method: 'GET',
    },
    url: `${url}${queryParams}`,
    ...passThroughProps,
  });
}

/**
 * Make a POST call.
 *
 * @param {object} config - The configuration object.
 * @param {object} [config.options] - Passed through as fetch options.
 * @param {object|string} config.params - POST body params.
 * @param {string} config.url - The URL endpoint path.
 *
 * @returns {Promise} The fetch response.
 */
export async function post({ options = {}, params, url, ...passThroughProps }) {
  const formBody = [];
  const keyValues = Object.entries(params);
  keyValues.forEach((entry) => {
    const encodedKey = encodeURIComponent(entry[0]);
    const encodedValue = encodeURIComponent(entry[1]);
    formBody.push(`${encodedKey}=${encodedValue}`);
  });

  /* istanbul ignore next */
  let finalBody = JSON.stringify(params);
  if (
    options?.headers?.['Content-Type'] === 'application/x-www-form-urlencoded'
  ) {
    finalBody = formBody.join('&');
  }

  return apiClient({
    options: {
      ...options,
      body: finalBody,
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        ...options.headers,
      },
      method: 'POST',
    },
    url,
    ...passThroughProps,
  });
}
