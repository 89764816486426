/**
 * @module RichText
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { filterAttributesForElement } from '@lifechurch/web-tools-io/dist/utils/helpers/attributes';
import { isColorSettingValidClassName } from '@lifechurch/web-tools-io/dist/utils/helpers/classNames';
import { implementAnchorTagAttributes } from '@lifechurch/web-tools-io/dist/utils/helpers/scriptHelper';
import { convertValueToClassName } from '@lifechurch/web-tools-io/dist/utils/helpers/validators';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import './RichText.scss';

/**
 * Represents a flexible element to display rich text as HTML markup.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.alignment] - Optional alignment value to use as a class name for the text (Example: 'text-left').
 * @param {string} [props.className] - Optional class name value to attribute to the container class.
 * @param {string} [props.colorsetting] - Color setting for the text (Example: 'text-white').
 * @param {string} [props.content] - The markup content to populate in the component.
 * @param {string} [props.sbOnMobile] - Class name to apply to component wrapper for mobile screen sizes.
 * @param {string} [props.sbOnTabletAndUp] - Class name to apply to component wrapper for tablet and up screen sizes.
 * @param {string} [props.sectionId] - Optional unique id value to assign to the container.
 *
 * @returns {React.ReactElement} The RichText component.
 */
function RichText({
  alignment = '',
  className = '',
  colorsetting,
  content,
  sbOnMobile,
  sbOnTabletAndUp,
  sectionId,
  ...passThroughProps
}) {
  const { isMobile } = useWindowSize();
  const [markup, setMarkup] = React.useState('');
  const colorSettingClass = convertValueToClassName(
    isColorSettingValidClassName(colorsetting) ? colorsetting : '',
  );
  const containerClass =
    `rich-text text-paragraph_large ${convertValueToClassName(
      className,
    )} ${colorSettingClass} ${convertValueToClassName(alignment)} ${
      isMobile
        ? convertValueToClassName(sbOnMobile)
        : convertValueToClassName(sbOnTabletAndUp)
    } container`.trim();
  const filteredProps = filterAttributesForElement({
    attributes: passThroughProps,
    elementType: 'div',
  });

  /**
   * Convenience effect to convert content to implement anchor tag attributes on
   * content set to be injected via dangerouslySetInnerHTML.
   */
  React.useEffect(() => {
    setMarkup(implementAnchorTagAttributes(content, 'RichText'));
  }, [content]);

  return (
    <div
      {...filteredProps}
      className={containerClass}
      dangerouslySetInnerHTML={{ __html: markup }}
      data-testid="lc-rich-text-component"
      id={sectionId}
    ></div>
  );
}

export default RichText;
