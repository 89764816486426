/**
 * @module CountrySelector
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import '../CampusCheckbox/CampusCheckbox.scss';
import './CountrySelector.scss';

/**
 * Represents a list item for a dropdown list with country name and checkbox element.
 *
 * @param {object} props - The component props object.
 * @param {string} props.name - The country name value.
 * @param {Function} props.setCountry - Handler function for country setting.
 * @param {Function} props.setToggleDropdown - Handler function for toggle of dropdown in which the component is contained.
 * @param {string} props.slug - The country slug value.
 * @param {string} props.value - The currently-selected value of the parent country container list, used to match with the provided slug to determine active/inactive status.
 *
 * @returns {React.ReactElement} The CountrySelector component.
 */
function CountrySelector({ name, setCountry, setToggleDropdown, slug, value }) {
  const isSelected = value === slug;

  function handleClick() {
    if (!isSelected) {
      setCountry(slug);
    }
    setToggleDropdown(false);
  }

  return (
    <div
      className={`campus-checkbox ${isSelected ? 'country-active' : ''}`}
      data-testid="lg-country-selector"
      onClick={handleClick}
    >
      <label className={'lg-location-checkbox'} htmlFor={name}>
        {name}
      </label>
      <span
        className={`checkbox ${isSelected ? 'campus-active' : ''}`.trim()}
      />
    </div>
  );
}

export default CountrySelector;
