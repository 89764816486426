/**
 * @module Analytics
 */
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { getExperimentalCookieValue } from './helpers';

export const ANALYTICS = {
  actions: {
    clicked: 'Clicked',
    closed: 'Closed',
    collapsed: 'Collapsed',
    created: 'Created',
    deleted: 'Deleted',
    dismissed: 'Dismissed',
    dragEnd: 'Drag End',
    dragStart: 'Drag Start',
    error: 'Error',
    expanded: 'Expanded',
    formSubmit: 'Form Submit',
    hidden: 'Hidden',
    loaded: 'Loaded',
    mouseEnter: 'Mouse Enter',
    mouseLeave: 'Mouse Leave',
    mouseOut: 'Mouse Out',
    opened: 'Opened',
    paused: 'Paused',
    played: 'Played',
    presented: 'Presented',
    ready: 'Ready',
    resize: 'Resize',
    selected: 'Selected',
    show: 'Show',
    signIn: 'Log In',
    stopped: 'Stopped',
    submitted: 'Submitted',
    success: 'Success',
    tapped: 'Tapped',
    toggledOff: 'Toggled Off',
    toggledOn: 'Toggled On',
    updated: 'Updated',
    zoomEnd: 'Zoom End',
    zoomStart: 'Zoom Start',
  },
  components: {
    banner: 'Banner',
    categoryBasedSearch: 'Category Based Search',
    countriesContainer: 'Countries Container',
    lifeGroupCard: 'LifeGroup Card',
    lifeGroupCardLegacy: 'LifeGroup Card (Legacy)',
    lifeGroupDetailPage: 'LifeGroup Detail Page',
    lifeGroupDetailPageContactFormModal:
      'LifeGroup Detail Page - Contact Form Modal',
    lifeGroupDetailPageStickBar: 'LifeGroup Detail Page - Sticky Bar',
    locationsContainer: 'Locations Container',
    locationContainerLocationCheckbox:
      'Locations Container - Location Checkbox',
    locationsContainerSearchInput: 'Locations Container - Search Input',
    locationSelector: 'Location Selector',
    menuModal: 'Menu Modal',
    moreFiltersContainer: 'More Filters Container',
    peopleFilterContainer: 'People Filter Container',
    searchOptionsModal: 'Search Options Modal',
    searchPage: 'Search Page',
    searchResults: 'Search Results',
    topicsFilterContainer: 'Topics Filter Container',
  },
  events: {
    accountLoggedIn: 'Account Logged In',
    accountLoggedInError: 'Account Logged In Error',
    accountLoggedOut: 'Account Logged Out',
    buttonAction: 'Button Action',
    buttonTap: 'Button Tap',
    currentLocationFound: 'Current Location Found',
    formSubmitted: 'Form Submitted',
    locationPermission: 'Location Permission',
    mapEvent: 'Map Event',
    searchStarted: 'Search Started',
    searchSubmitted: 'Search Submitted',
    selectorDismissed: 'Selector Dismissed',
    selectorPresented: 'Selector Presented',
  },
  eventTypes: {
    track: 'track',
    view: 'view',
  },
  forms: {
    search: 'Search',
  },
  labels: {
    back: 'Back',
    close: 'Close',
    cookiePreferences: 'Cookie Preferences',
    dismiss: 'Dismiss',
    done: 'Done',
    help: 'Help',
    location: 'Location',
    privacyPolicy: 'Privacy Policy',
    signIn: 'Log In',
    termsOfUse: 'Terms of Use',
    unavailable: 'Unavailable',
    vulnerabilityDisclosureProgram: 'Vulnerability Disclosure Program',
  },
  pages: {
    error: 'Error',
    maintenance: 'Maintenance',
    outage: 'Outage',
  },
  screens: {
    classes: {
      error: 'Error',
      lifeGroupsSearch: 'LifeGroups Search',
      maintenance: 'Maintenance',
      outage: 'Outage',
    },
    names: {
      error: 'Error',
      lifeGroupsSearch: 'LifeGroups Search',
      maintenance: 'Maintenance',
      outage: 'Outage',
    },
  },
};

/**
 * Convenience function to trigger Segment track() call. The function automatically adds the following properties:
 * • experimental_flag
 * • index
 * • logged_in
 * • preferred_campus
 * • queryId
 * • referrer
 * • title
 * • url
 * • user_id
 *
 * @param {object} params - The function params object.
 * @param {object} params.dataForAnalytics - Data object of Analytics-related values from Algolia context.
 * @param {string} params.event - The event name.
 * @param {object} params.properties - Data object of properties spread on to built-in properties attribute.
 * @param {object} params.user - The user data object from the authentication provider (for authenticated users).
 * @param {object} params.userProfile - The Life.Church Profile API user profile data object (for authenticated users).
 */
export function triggerSegmentTrack({
  dataForAnalytics,
  event,
  properties,
  user,
  userProfileData,
}) {
  callSegmentTrack({
    event,
    properties: {
      experimental_flag: getExperimentalCookieValue(),
      index: dataForAnalytics?.indexName || null,
      logged_in: !!user,
      preferred_campus: userProfileData?.preferred_campus_code || null,
      queryId: dataForAnalytics?.queryId || null,
      referrer: document?.referrer || null,
      title: document?.title || '',
      url: window?.location?.href,
      user_id: user?.['https://www.life.church/rock_person_alias_id'],
      ...properties,
    },
  });
}
