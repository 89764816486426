/**
 * @module Constants
 */

// Environment
export const ENVIRONMENT = {
  dev: process.env.NODE_ENV === 'development',
  development: process.env.NODE_ENV === 'development',
  prod: process.env.NODE_ENV === 'production',
  production: process.env.NODE_ENV === 'production',
  review: process.env.NODE_ENV === 'review',
  staging: process.env.NODE_ENV === 'staging',
};

// App Vars
export const APP_NAME = 'LifeGroups Search';

export const APP_CONFIG = {
  appViews: {
    lgDetailPage: 'lgDetailPage',
    searchPage: 'searchPage',
  },
  baseUrl: `https://${
    !ENVIRONMENT.production ? 'staging.' : 'www.'
  }life.church`,
  ga4MeasurementId: process.env.REACT_APP_GA4_MEASUREMENT_ID,
  myLifeChurchLgDomain: process.env.LG_ROCK_BASE_URL,
  servingOpportunityGuid: 'a24ee97e-e219-48ec-bf0d-2ac94f8355f3',
};

export const URLS = {
  churchOnlineLinkUrl:
    'https://lp.life.church/onlinelifegroup/?utm_medium=referral&utm_source=life_church&utm_campaign=co_life_group&utm_content=co_start_life_group',
  lifeGroupLinkUrl: `https://www.life.church/webview-app/multistep-forms/serving/?utm_source=web&utm_medium=lifegroup-search-app&opportunity=${APP_CONFIG.servingOpportunityGuid}`,
  switch: '//life.church/switch/',
};

export const CONTENT_MODES = Object.freeze({
  dangerouslySetInnerHTML: 'dangerouslySetInnerHTML',
  default: 'default',
});

export const ERROR_MODES = Object.freeze({
  error: 'error',
  maintenance: 'maintenance',
  outage: 'outage',
});

export const STATUS_TYPES = Object.freeze({
  error: 'error',
  idle: 'idle',
  info: 'info',
  pending: 'pending',
  rejected: 'rejected',
  resolved: 'resolved',
  success: 'success',
  warning: 'warning',
});

export const USER_DATA_ERROR_MAP = {
  Email: 'Email',
  FirstName: 'First Name',
  LastName: 'Last Name',
  MobilePhone: 'Phone Number',
  PreferredContactMethod: 'Preferred Contact Method',
};

// Experiments
export const EXPERIMENTS = {
  cookieName: 'lg-search-experiment-flag',
  enabled:
    process.env.ENABLE_EXPERIMENTS &&
    process.env.ENABLE_EXPERIMENTS.toString() === 'true',
  values: {
    baseline: {
      cookieValue: 'baseline',
      label: 'Baseline',
    },
    categoryBasedSearch: {
      cookieValue: 'category-based-search',
      label: 'Category-Based Search',
    },
    moreLikeThis: {
      cookieValue: 'more-like-this',
      label: 'More Like This',
    },
    pagination: {
      cookieValue: 'pagination',
      label: 'Pagination',
    },
  },
};

/**
 * URL Search Values that override default behavior of only showing experiments
 * if there is no query string on the URL.
 */
export const EXPERIMENT_URL_SEARCH_OVERRIDES = [
  '?campus=all&groupTypes=lifegroups',
];

export const OVERRIDE_ALGOLIA_QUERY_KEY = 'lc-lt-override';

// Mapbox
export const MAPBOX_CONFIG = {
  accessToken: process.env.MAPBOX_ACCESS_TOKEN,
  styleToken: process.env.MAPBOX_STYLE_TOKEN,
};

// Categories
export const DAYS_OF_WEEK = [
  { name: 'Sun', value: 'Su' },
  { name: 'Mon', value: 'Mo' },
  { name: 'Tue', value: 'Tu' },
  { name: 'Wed', value: 'We' },
  { name: 'Thur', value: 'Th' },
  { name: 'Fri', value: 'Fr' },
  { name: 'Sat', value: 'Sa' },
];

export const DAYS_OF_WEEK_MAP = {
  friday: 'Fri',
  monday: 'Mon',
  saturday: 'Sat',
  sunday: 'Sun',
  thursday: 'Thu',
  tuesday: 'Tue',
  varies: 'Varies',
  wednesday: 'Wed',
};

export const GROUP_TYPES = [
  { name: 'LifeGroups', value: 'LifeGroups' },
  { name: 'Local Partners', value: 'Local Partner' },
];

export const GROUP_TYPES_MAP = {
  lifeGroups: ['lifegroups', 'lifeGroups', 'LifeGroups'],
  localPartner: [
    'local-partner',
    'localPartner',
    'LocalPartner',
    'Localpartner',
    'local partner',
    'Local Partner',
  ],
};

export const TAG_COLORS = {
  anyone: {
    background: 'rgba(255, 128, 0, 0.12)',
    border: 'rgba(188, 107, 24, 0.3)',
    content: 'rgba(188, 107, 24, 1)',
    overlay: 'rgba(255, 128, 0, 0.2)',
  },
  couples: {
    background: 'rgba(129, 0, 255, 0.1)',
    border: 'rgba(89, 43, 134, 0.3)',
    content: 'rgba(89, 43, 134, 1)',
    overlay: 'rgba(129, 0, 255, 0.2)',
  },
  default: {
    background: 'rgba(255, 255, 255, 1)',
    border: 'rgba(198, 198, 198, 1)',
    content: 'rgba(64, 64, 65, 1)',
    overlay: 'rgba(255, 255, 255, 0.2)',
  },
  men: {
    background: 'rgba(3, 198, 148, 0.13)',
    border: 'rgba(0, 148, 110, 0.3)',
    content: 'rgba(0, 148, 110, 1)',
    overlay: 'rgba(3, 198, 148, 0.2)',
  },
  placeholder: {
    background: 'rgba(236, 236, 236, 1)',
    border: 'rgba(236, 236, 236, 1)',
    content: 'rgba(236, 236, 236, 1)',
    overlay: 'rgba(236, 236, 236, 0.2)',
  },
  topics: {
    background: 'rgba(0, 200, 255, 0.1)',
    border: 'rgba(0, 116, 148, 0.3)',
    content: 'rgba(0, 116, 148, 1)',
    overlay: 'rgba(0, 200, 255, 0.2)',
  },
  transparent: {
    background: 'rgba(255, 255, 255, 0)',
    border: 'rgba(255, 255, 255, 0)',
    content: 'rgba(255, 255, 255, 0)',
    overlay: 'rgba(255, 255, 255, 0)',
  },
  women: {
    background: 'rgba(222, 49, 130, 0.1)',
    border: 'rgba(222, 49, 130, 0.3)',
    content: 'rgba(222, 49, 130, 1)',
    overlay: 'rgba(222, 49, 130, 0.2)',
  },
};

export const SNAKE_CASE_EXCEPTIONS = [
  ['_i_d', '_id'],
  ['_u_r_l', '_url'],
];

// -----------------------------------------------------------------------------
//  Start: Default Context State Values
// -----------------------------------------------------------------------------
export const DEFAULT_CONTEXT_STATE_VALUES = {
  age: '',
  campus: null,
  campusList: [],
  campusSearch: '',
  children: [],
  churchOnlineLocation: {
    id: null,
    name: 'All Locations',
    slug: 'all',
  },
  churchOnlineLocations: [],
  countrySearch: '',
  days: [],
  filteredList: [],
  genders: [],
  groupTypes: 'LifeGroups',
  isAlgoliaAvailable: true,
  isLocationFiltersToggled: false,
  isMoreFiltersToggled: false,
  isPeopleFiltersToggled: false,
  isSearchTrayExpanded: true,
  isTopicFiltersToggled: false,
  keywords: '',
  lifegroupsList: {
    data: [],
    hasMoreItems: false,
    links: {},
    meta: {},
  },
  meetingFrequency: [],
  meetingType: [],
  overrideAlgolia: false,
  resultsPagination: {
    isMore: false,
    offset: 0,
    page: 0,
    resultsLength: 0,
    total: 0,
  },
  seasonOfLife: [],
  topics: [],
};
// -----------------------------------------------------------------------------
//  End: Default Context State Values
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
//  Start: LG Search API Configuration
// -----------------------------------------------------------------------------
export const LGS_API_CONFIG = {
  filters: {
    'filter[campus_code]': {
      name: 'filter[campus_code]',
      queryKey: 'campus',
    },
    'filter[group_type]': {
      name: 'filter[group_type]',
      queryKey: 'groupTypes',
    },
    'filter[keywords]': {
      name: 'filter[keywords]',
      queryKey: 'keywords',
    },
  },
  searchApiOptions: {
    offset: 0,
    page: 0,
    resultsLength: 24,
  },
};
// -----------------------------------------------------------------------------
//  End: LG Search API Configuration
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
//  Start: Algolia Configuration
//
//  Source: https://www.algolia.com/doc/rest-api/search/#tag/Search/operation/searchSingleIndex
// -----------------------------------------------------------------------------
export const ALGOLIA_CONFIG = {
  facets: {
    'facets.campuses': {
      name: 'facets.campuses',
      queryKey: 'campuses',
    },
    'facets.children': {
      name: 'facets.children',
      options: {
        adultsOnly: 'Adults Only',
        childcareAvailable: 'Childcare Available',
        childrenWelcome: 'Children Welcome',
      },
      queryKey: 'children',
    },
    'facets.genders': {
      name: 'facets.genders',
      queryKey: 'genders',
    },
    'facets.isShortTermGroup': {
      name: 'facets.isShortTermGroup',
      queryKey: 'isShortTermGroup',
    },
    'facets.meetingDayOfWeekFull': {
      name: 'facets.meetingDayOfWeekFull',
      queryKey: 'days',
    },
    'facets.meetingFrequency': {
      name: 'facets.meetingFrequency',
      queryKey: 'frequency',
    },
    'facets.meetingType': {
      name: 'facets.meetingType',
      queryKey: 'meetingType',
    },
    'facets.seasonOfLife': {
      name: 'facets.seasonOfLife',
      queryKey: 'seasonOfLife',
    },
    'facets.topic.name': {
      name: 'facets.topic.name',
      queryKey: 'topic',
    },
    'facets.type': {
      name: 'facets.type',
      queryKey: 'groupTypes',
    },
    'groupLeaders.firstName': {
      name: 'groupLeaders.firstName',
      queryKey: 'groupLeaders',
    },
    'meetingLocation.country': {
      name: 'meetingLocation.country',
      queryKey: 'meetingLocation',
    },
  },
  searchApiOptions: {
    offset: 0,
    page: 0,
    recommendationsLength: 4,
    recommendationsResultsLength: 50,
    resultsLength: 60, // Number of hits to retrieve at one time. Actual Algolia value is 'length'; renamed to avoid confusion with JS .length property.
  },
  v1AttributeMap: {
    campus: {
      queryKey: 'campuses',
    },
    categories: [
      {
        algoliaValue: 'Anyone',
        queryKey: 'genders',
        v1: 'everyone welcome',
      },
      {
        algoliaValue: 'Women',
        queryKey: 'genders',
        v1: "women's",
      },
      {
        algoliaValue: 'Men',
        queryKey: 'genders',
        v1: "men's",
      },
      {
        algoliaValue: 'Single',
        queryKey: 'seasonOfLife',
        v1: "single's",
      },
      {
        algoliaValue: 'Student',
        queryKey: 'seasonOfLife',
        v1: 'college',
      },
      {
        algoliaValue: 'Young Adult',
        queryKey: 'seasonOfLife',
        v1: 'young adults',
      },
      {
        algoliaValue: 'Life.Church Online Watch Party',
        queryKey: 'topic',
        v1: 'watch party',
      },
      {
        algoliaValue: 'Married',
        queryKey: 'seasonOfLife',
        v1: 'married',
      },
      {
        algoliaValue: 'Mentoring',
        queryKey: 'topic',
        v1: 'mentor',
      },
    ],
    group_location: {
      queryKey: 'meetingLocation',
    },
    kidsWelcome: {
      algoliaCounterpart: ['Childcare Available', 'Children Welcome'],
      queryKey: 'children',
    },
    meetOnline: {
      algoliaCounterpart: ['Online'],
      queryKey: 'meetingType',
    },
  },
};
// -----------------------------------------------------------------------------
//  End: Algolia Configuration
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
//  Life.Church Online Data
//
//  Note: Life.Church Online locations are presently not part of Algolia, and
//  were part of the LG Search API. With that being deprecated, the initial
//  solution is to keep a hard-coded local constant set of data to use until
//  fully and properly supported.
// -----------------------------------------------------------------------------
export const LIFECHURCH_ONLINE_LOCATIONS = [
  'US',
  'AU',
  'PH',
  'GB',
  'AT',
  'JP',
  'CA',
  'BR',
  'TH',
  'YE',
  'IT',
  'MW',
  'TZ',
  'KE',
  'LR',
  'NG',
  'BD',
  'NP',
  'PK',
  'IN',
  'JM',
  'GH',
  'HT',
  'CO',
  'SA',
  'SL',
  'UG',
  'BO',
  'ZM',
  'NE',
  'MX',
  'RW',
  'TG',
  'DO',
  'ZW',
  'CR',
  'CM',
  'ZA',
  'BG',
  'NL',
  'SK',
  'NA',
  'KR',
  'AR',
];
// -----------------------------------------------------------------------------
//  Locations Exceptions and Metro Groups and Data
//
//  Note: Magnolia API does not presently include metro groups or info on any
//  of the locations. As such, the initial solution is to keep a hard-coded
//  local constant set of data to use until fully and properly supported.
// -----------------------------------------------------------------------------
// Campus locations with different name values from API vs Algolia.
export const CAMPUS_VARIATIONS = {
  noc: {
    algolia: 'Northwest Oklahoma City',
    magnolia: 'NW Oklahoma City',
  },
};

// Locations Metro Groups
export const METRO_GROUPS = {
  1: {
    name: 'Oklahoma City Metro',
  },
  2: {
    name: 'Tulsa Metro',
  },
  3: {
    name: 'Kansas City Metro',
  },
  4: {
    name: 'Wichita Metro',
  },
  5: {
    name: 'Dallas/Fort Worth Metro',
  },
};

export const LOCATIONS_METRO_DATA = {
  alb: null,
  ams: null,
  atn: null,
  bao: 2,
  bnb: 1,
  clv: null,
  cta: 2,
  drb: 4,
  edm: 1,
  ekc: 3,
  fts: null,
  ftw: 5,
  hnv: null,
  jnk: 2,
  klr: 5,
  lnx: 3,
  mky: 5,
  mor: 1,
  msf: 5,
  mus: 1,
  mwc: 1,
  ncs: null,
  nkc: 3,
  noc: 1,
  nor: 1,
  okc: 1,
  omh: null,
  opk: 3,
  ows: 2,
  pkr: null,
  rga: null,
  rrn: null,
  sba: 2,
  shw: null,
  soc: 1,
  spf: null,
  sto: 2,
  stw: null,
  thr: null,
  tul: 2,
  wch: 4,
  wel: null,
  wpb: null,
  wwk: 4,
  ykn: 1,
};

// -----------------------------------------------------------------------------
//  Log Configuration
// -----------------------------------------------------------------------------
export const LOG_CONFIG = {
  colorize:
    process.env.LOG_OPTIONS_COLORIZE &&
    process.env.LOG_OPTIONS_COLORIZE.toString() === 'true',
  includeTimestamp:
    process.env.LOG_OPTIONS_INCLUDE_TIMESTAMP &&
    process.env.LOG_OPTIONS_INCLUDE_TIMESTAMP.toString() === 'true',
  label: process.env.LOG_OPTIONS_LABEL || 'LifeGroup Search App V2',
  printOutput:
    (process.env.LOG_OPTIONS_PRINT_OUTPUT &&
      process.env.LOG_OPTIONS_PRINT_OUTPUT.toString() === 'true') ||
    !ENVIRONMENT.production,
};

// -----------------------------------------------------------------------------
//  Magnolia Helpers
// -----------------------------------------------------------------------------
export const LOCAL_STORAGE_KEYS = {
  auth0: {
    redirectOriginParams: 'LC:AUTH:RedirectOriginParams',
  },
  consentManager: {
    gtmConsent: 'LC:CM:GtmConsent',
    userPreferences: 'LC:CM:ConsentPreferences:Main',
  },
};

/**
 * Authentication (Auth0) Routes. Note that this includes an array of URLs that
 * have both trailing slash and non-trailing slash values, as Fastly applies the
 * slash for production but it isn't always present for Staging or localhost.
 */
export const AUTH_ROUTES = [
  '/auth/auth0',
  '/auth/auth0/',
  '/auth/auth0/callback',
  '/auth/auth0/callback/',
  process.env.AUTH_CALLBACK_URL,
  '/logout',
  '/logout/',
  '/orgsignup',
  '/orgsignup/',
];

// -----------------------------------------------------------------------------
//  Magnolia Helpers
// -----------------------------------------------------------------------------
export const MGNL_ENV_VARS = {
  REACT_APP_CMS: process.env.REACT_APP_CMS,
  REACT_APP_MGNL_API_CONTENT_TYPE: process.env.REACT_APP_MGNL_API_CONTENT_TYPE,
  REACT_APP_MGNL_API_NAV: process.env.REACT_APP_MGNL_API_NAV,
  REACT_APP_MGNL_API_PAGES: process.env.REACT_APP_MGNL_API_PAGES,
  REACT_APP_MGNL_API_TEMPLATES: process.env.REACT_APP_MGNL_API_TEMPLATES,
  REACT_APP_MGNL_APP_BASE: process.env.REACT_APP_MGNL_APP_BASE,
  REACT_APP_MGNL_AUTHOR: process.env.REACT_APP_MGNL_AUTHOR,
  REACT_APP_MGNL_BASE_AUTHOR: process.env.REACT_APP_MGNL_BASE_AUTHOR,
  REACT_APP_MGNL_BASE_PUBLIC: process.env.REACT_APP_MGNL_BASE_PUBLIC,
  REACT_APP_MGNL_DAM_RAW: process.env.REACT_APP_MGNL_DAM_RAW,
  REACT_APP_MGNL_HOST: process.env.REACT_APP_MGNL_HOST,
  REACT_APP_MGNL_IS_PREVIEW: process.env.REACT_APP_MGNL_IS_PREVIEW,
  REACT_APP_MGNL_LANGUAGES: process.env.REACT_APP_MGNL_LANGUAGES,
  REACT_APP_MGNL_STATIC: process.env.REACT_APP_MGNL_STATIC,
};

export const ENDPOINT_WORKSPACE_MAP = {
  '/.rest/delivery/footer': 'footer',
  '/.rest/delivery/menu': 'menu',
  '/.rest/delivery/pagenav/v1': 'website',
  '/.rest/delivery/pages/v1': 'website',
};

// -----------------------------------------------------------------------------
//  Consent Manager Helpers
// -----------------------------------------------------------------------------
export const CONSENT_MANAGER_CONFIG = {
  bannerContent:
    "<span>We use cookies and other similar technologies to collect data to improve your experience on our site. By using our website, you're agreeing to the collection of data as described in our <a href='https://life.church/privacy' target='_blank' title='View the Life.Church Privacy Policy'>Privacy Policy</a>.</span>",
  bannerSubContent: 'You can change your preferences any time.',
  buttonLabels: {
    allowAll: 'Allow All',
    denyAll: 'Deny All',
    save: 'Save',
  },
  categories: {
    advertising: {
      categories: ['Advertising'],
      description:
        'Advertising cookies are used to provide relevant and interest-based content to you. We may share this information with advertisers or use it to understand your interests better. For example, advertising cookies may be used to share data with advertisers, making ads more relevant to you and allowing you to share certain pages with social networks or post comments.',
      order: 2,
      title: 'Advertising',
    },
    analyticsMarketing: {
      categories: ['Analytics', 'Email Marketing'],
      description:
        'Analytics tracking is done to evaluate and understand user behavior to provide you with a more relevant browsing experience or personalize the content of our site.',
      order: 1,
      title: 'Marketing and Analytics',
    },
    functionality: {
      categories: ['Functionality'],
      description:
        'Functional cookies are used to provide and support core functionality for the website.',
      order: 3,
      title: 'Functionality',
    },
  },
  categoryMap: {
    Advertising: 'advertising',
    Analytics: 'analyticsMarketing',
    'Email Marketing': 'analyticsMarketing',
    Functionality: 'functionality',
  },
  customDestinations: [
    {
      category: 'Functionality',
      description:
        'Functional cookies are used to provide and support core functionality for the website.',
      id: 'Functionality',
      name: 'Functionality',
      website: '',
    },
  ],
  disclaimer:
    '<p><i>Saving changed settings may reload the page to ensure your preferences take effect.</i></p>',
  footerLinkCTA: 'Cookie Preferences',
  preferencesDialogContent:
    'We use data collected by cookies and JavaScript libraries to improve your browsing experience, analyze site traffic, deliver personalized content, and increase the overall performance of our site.',
  preferencesDialogTitle: 'Website Data Collection Preferences',
  productionDestinationOverrides: [
    {
      category: 'Analytics',
      description:
        'Amplitude is an event tracking and segmentation platform for your web and mobile apps. By analyzing the actions your users perform, you can gain a better understanding to drive retention, engagement, and conversion.',
      id: 'Actions Amplitude',
      name: 'Amplitude (Actions)',
      website: 'https://amplitude.com',
    },
    {
      category: 'Email Marketing',
      description: 'Send events server-side to the Braze REST API.',
      id: 'Braze Cloud Mode (Actions)',
      name: 'Braze Cloud Mode (Actions)',
      website: 'https://www.braze.com/',
    },
    {
      category: 'Advertising',
      description:
        'The Facebook Pixel integration lets you measure and optimize the performance of your Facebook Ads.',
      id: 'Facebook Pixel',
      name: 'Facebook Pixel',
      website: 'https://developers.facebook.com/docs/facebook-pixel',
    },
    {
      category: 'Analytics',
      description:
        'Google Analytics 4 (GA4) is Google’s new Analytics property, which you can use for both websites and apps. Google Analytics 4 has machine learning at its core to automatically surface helpful insights and give you a complete understanding of your customers across devices and platforms. When you have Segment installed, you can make efficient use of your existing tracking implementation by using Segment to fulfill your data collection needs across all your tools that integrate with Segment, including Google Analytics 4. The Google Analytics 4 Web destination works on the client by loading gtag.js for you.',
      id: 'Google Analytics 4 Web',
      name: 'Google Analytics 4 Web',
      website: 'https://support.google.com/analytics/answer/10089681',
    },
    {
      category: 'Analytics',
      description:
        'Mixpanel is an event tracking tool targeted at web apps with lots of features: funnel, retention and people tracking; advanced segmentation; and sending email and notifications. ',
      id: 'Mixpanel (Actions)',
      name: 'Mixpanel (Actions)',
      website: 'http://mixpanel.com',
    },
  ],
};

export const CONSENT_MANAGER_THIRD_PARTY_SERVICES = {
  google: {
    destinations: ['Google Analytics', 'Google Analytics 4 Web'],
    enabled: false,
    name: 'Google',
    scriptSrcUrls: ['googleoptimize.com', 'googletagmanager.com'],
  },
};
